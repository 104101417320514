import { FC } from "react";
import useHubspotForm from "@aaronhayes/react-use-hubspot-form";

type Props = {
    portalId: number;
    formId: string;
};

export const HubspotForm: FC<Props> = (props: Props) => {
    const { portalId, formId } = props;

    useHubspotForm({
        portalId: portalId.toString(),
        formId: formId,
        target: "#hubspot",
    });

    return <div id="hubspot"></div>;
};
