import { FC, useState } from "react";
import { Col, Container, Row, Modal, ModalBody } from "reactstrap";
import { HubspotForm } from "./HubspotForm";
import ImgGreenFace from "../img/results/green-face.png";
import ImgPurpleFace from "../img/results/purple-face.png";

export const NewsletterSignup: FC = () => {
    const [modalContact, setModalContact] = useState(false);
    const [modalSubscribe, setModalSubscribe] = useState(false);
    const toggleContact = () => setModalContact(!modalContact);
    const toggleSubscribe = () => setModalSubscribe(!modalSubscribe);

    return (
        <section className="section-newsletter">
            <Container>
                <Row>
                    <Col lg={4} className="text-start">
                        <h2 className="type-s underline underline--red mb-4">Let's get to it</h2>
                    </Col>

                    <Col lg={4}>
                        <div className="text-start">
                            <p className="type-copy mb-5">
                                Use your results to help you reflect on how you could improve your collaboration
                                approach. There’s a lot to consider so print, bookmark or save this page.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="card--spacer">
                    <Col lg={4}>
                        <div className="result__card card--purple">
                            <img className="card__img" src={ImgGreenFace}></img>
                            <h3 className="mb-3">Say hi!</h3>
                            <p className="type-copy mb-0">
                                If you’d like to discuss how we can help your team sharpen their collaboration approach
                                or have some feedback on the Collab Explorer.
                            </p>
                            <button className="card__cta btn btn-primary" onClick={toggleContact}>
                                Get in touch
                            </button>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="result__card card--yellow">
                            <img className="card__img" src={ImgPurpleFace}></img>
                            <h3 className="mb-3">Not ready to chat yet?</h3>
                            <p className="type-copy mb-0">
                                Subscribe to our newsletter and be in the loop for more ideas on sharpening your digital
                                skills.
                            </p>
                            <button className="card__cta btn btn-primary" onClick={toggleSubscribe}>
                                Subscribe
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="text-start">
                        <h2 className="type-s underline underline--red mb-4">One more thing</h2>
                    </Col>

                    <Col lg={4}>
                        <div className="text-start">
                            <p className="type-copy mb-0">
                                Find the latest articles, news, events and more on our Collab page.
                            </p>
                            <a
                                className="btn btn-primary button--spacer"
                                href="https://liquidinteractive.com.au/collab"
                            >
                                Collab page
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modalContact} toggle={toggleContact} className="modal-dialog-centered">
                <ModalBody>
                    <button className="btn-close" onClick={toggleContact} aria-label="Close"></button>
                    <div className="modal-header">Get in touch</div>
                    <HubspotForm portalId={4510557} formId="5e63adcd-6a99-4e23-b96c-78a7cd98da8e" />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalSubscribe} toggle={toggleSubscribe} className="modal-dialog-centered">
                <ModalBody>
                    <button className="btn-close" onClick={toggleSubscribe} aria-label="Close"></button>
                    <div className="modal-header">Sign up to our newsletter</div>
                    <HubspotForm portalId={4510557} formId="bd07d2fa-c152-4acb-8721-4c3bc89bc789" />
                </ModalBody>
            </Modal>
        </section>
    );
};
