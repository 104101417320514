import { SurveyResponseModel } from "../models/crud/SurveyResponseModel";
import { SurveyModel } from "../models/SurveyModel";
import { BaseApiService } from "./BaseApiService";

export class SurveyService extends BaseApiService {
    static submitSurvey(model: SurveyResponseModel): Promise<any> {
        return this.post<any>("/survey/submit", model);
    }

    static getSurvey(id: number): Promise<SurveyModel> {
        return this.get(`/survey/${id}`);
    }
}
