import clsx from "clsx";
import { FC } from "react";
import { SurveyDimensionBreakpoint } from "../models/SurveyModel";

type Props = {
    bIndex: number;
    breakpoints: SurveyDimensionBreakpoint[];
    currentBreakpoint: SurveyDimensionBreakpoint;
};

export const Tracks: FC<Props> = (props: Props) => {
    const { bIndex, breakpoints, currentBreakpoint } = props;

    return (
        <div className="d-flex align-items-center justify-content-center">
            <ul
                className={clsx("tracks", [
                    bIndex === 0 && "tracks--red",
                    bIndex === 1 && "tracks--purple",
                    bIndex === 2 && "tracks--blue",
                    bIndex === 3 && "tracks--green",
                    bIndex === 4 && "tracks--blue2",
                    bIndex === 5 && "tracks--pink",
                ])}
            >
                {breakpoints.map(listBreakpoint => {
                    const selected = listBreakpoint.ID === currentBreakpoint.ID;

                    return (
                        <li key={listBreakpoint.ID} className={clsx(selected && ["current"])}>
                            <span className="tracks__text">{listBreakpoint.Name}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
