import { Fragment } from "react";
import { useHistory } from "react-router";
import { RouteList } from "../../Routes";

type Props = {
    answers: Array<string>;
};

export const RedirectResultsId = (props: Props) => {
    const history = useHistory();

    history.push(RouteList.Results(props.answers));

    return <Fragment>Redirecting</Fragment>;
};
