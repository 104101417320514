import { FC, Fragment, lazy, Suspense, useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ThemeType } from "../../utils/Types";

type Props = {
    theme?: ThemeType;
};

// this class is a hard coded switch to dynamically load styles from an API call
// ideally this dynamically finds a stylesheet
// this wasn't working well with Bootstrap when dynamic imports end up as CSS modules
// and I couldn't figure out how to disable CSS modules in time
// so this hard coded for now until something more solid can be found
export const ThemeSelector: FC<Props> = (props: Props) => {
    const { theme } = props;

    const { setLoading } = useContext(GlobalContext);

    // we need to set loading false only after the import CSS has completed
    // so that we avoid FOUC
    const ThemeSomethingDigital = lazy(() =>
        import("./ThemeSomethingDigital").then(({ ThemeSomethingDigital }) => {
            setLoading(false);
            return { default: ThemeSomethingDigital };
        }),
    );

    const ThemeBad = lazy(() =>
        import("./ThemeBad").then(({ ThemeBad }) => {
            setLoading(false);
            return { default: ThemeBad };
        }),
    );

    if (theme === "something-digital") {
        return (
            <Suspense fallback={<Fragment></Fragment>}>
                <ThemeSomethingDigital />
            </Suspense>
        );
    }

    if (theme === "bad") {
        return (
            <Suspense fallback={<Fragment></Fragment>}>
                <ThemeBad />
            </Suspense>
        );
    }

    return null;
};
