import clsx from "clsx";
import { CSSProperties, FC, PropsWithChildren, useEffect } from "react";

type Props = {
    className?: string;
    style?: CSSProperties;
};

export const Page: FC<Props> = (props: PropsWithChildren<Props>) => {
    const { children, className, style } = props;

    useEffect(() => {
        // scroll page to top on route change
        window.scroll(0, 0);

        // clear all root classes
        document.getElementById("root")?.removeAttribute("class");
    }, []);

    return (
        <main style={style} className={clsx(className)}>
            {children}
        </main>
    );
};
