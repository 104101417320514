import { useContext, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { Button, Col, Container, Row } from "reactstrap";
import { createMarkup } from "../utils/Helpers";

type Props = {
    submitSurvey: () => void;
};

export const SurveyCompletion = (props: Props) => {
    const { submitSurvey } = props;

    const { survey } = useContext(GlobalContext);

    const [showStep2, setShowStep2] = useState(false);

    return (
        <Container>
            <Row>
                <Col md={{ size: 6, offset: 1 }}>
                    {showStep2 && survey?.Content.Survey.CompletedScreen2 != null ? (
                        <div className="container-mini">
                            <div
                                className="type-m fw-normal mb-5 completed-2"
                                dangerouslySetInnerHTML={createMarkup(survey?.Content.Survey.CompletedScreen2)}
                            ></div>

                            <Button type="button" onClick={submitSurvey} color="primary">
                                View results
                            </Button>
                        </div>
                    ) : (
                        <div className="completed-1">
                            <div className="spacer"></div>

                            <div className="text-center">
                                <h1 className="type-l underline underline--red completed-1__title">That’s it!</h1>
                            </div>

                            <p className="type-s text-center mb-5 completed-1__description">
                                Let’s check out your results.
                            </p>

                            {survey?.Content.Survey.CompletedScreen1 && (
                                <div
                                    className="completed-1__inner mb-5"
                                    dangerouslySetInnerHTML={createMarkup(survey?.Content.Survey.CompletedScreen1)}
                                ></div>
                            )}

                            {survey?.Content.Survey.CompletedScreen2 == null ? (
                                <Button type="button" onClick={submitSurvey} color="primary">
                                    Show me my results
                                </Button>
                            ) : (
                                <div className="text-center">
                                    <Button type="button" onClick={() => setShowStep2(true)} color="primary">
                                        Okay
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
