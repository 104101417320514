import { HomePage } from "./pages/HomePage";
import { SurveyPage } from "./pages/SurveyPage";
import { ResultsPage } from "./pages/ResultsPage";
import { RedirectSurveyId } from "./components/redirect/RedirectSurveyId";
import { RedirectResultsId } from "./components/redirect/RedirectResultsId";

export const RouteList = {
    SurveyOldRedirected: (id?: number | null) => `/survey/${id ?? ":id"}`,
    Survey: "/survey",
    ResultsOldRedirected: (answers?: Array<string> | null, id?: number | null) =>
        `/results/${id ?? ":id"}/${answers ?? ":answers"}`,
    Results: (answers?: Array<string> | null) => `/results/${answers ?? ":answers"}`,
    Home: "/",
};

export const RouteTable = [
    { path: RouteList.SurveyOldRedirected(), Component: RedirectSurveyId },
    { path: RouteList.Survey, Component: SurveyPage },
    { path: RouteList.ResultsOldRedirected(), Component: RedirectResultsId },
    { path: RouteList.Results(), Component: ResultsPage },
    { path: RouteList.Home, Component: HomePage },
];
