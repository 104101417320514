import { FC, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Icon } from "../components/Icon";
import { RouteList } from "../Routes";
import { Page } from "./Page";
import { GlobalContext } from "../contexts/GlobalContext";

export const HomePage: FC = () => {
    const { survey } = useContext(GlobalContext);

    useEffect(() => {
        // set special class on root element to handle small laptop specific image overflow bug
        document.getElementById("root")?.classList.add("root--home");
    }, []);

    return (
        <Page className="page-home">
            {survey && (
                <Container className="my-auto container--full">
                    <div className="introduction">
                        <h1 className="type-xxl introduction__title">{survey.Content.Introduction.Title}</h1>

                        <div className="mb-5">
                            <p className="type-copy mb-3">{survey.Content.Introduction.Description1}</p>

                            {survey.Content.Introduction.Description2 && (
                                <p className="type-copy">{survey.Content.Introduction.Description2}</p>
                            )}
                        </div>

                        <Link to={RouteList.Survey} className="btn btn-primary mb-5">
                            Let's go
                            <Icon icon="arrow" />
                        </Link>
                    </div>
                </Container>
            )}
        </Page>
    );
};
