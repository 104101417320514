import "@popperjs/core";
import "bootstrap";
import "./css/init.scss";
import { FC, Fragment, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RouteTable } from "./Routes";
import { Constants } from "./utils/Constants";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { GlobalContext } from "./contexts/GlobalContext";
import { ThemeSelector } from "./components/themes/ThemeSelector";

export const App: FC = () => {
    const { animationDuration } = Constants;

    const { survey, loading } = useContext(GlobalContext);

    useEffect(() => {
        // set CSS animation variable here so it's referenced in on place in JS
        document.documentElement.style.setProperty("--animation-duration", `${animationDuration}ms`);
    }, [animationDuration]);

    return (
        <BrowserRouter>
            <ThemeSelector theme={survey?.Theme} />

            {loading ? (
                <div className="init">Loading</div>
            ) : (
                <Fragment>
                    <Navbar />

                    <Switch>
                        {RouteTable.map(({ path, Component }) => (
                            <Route exact path={path} key={path}>
                                {({ match }) => <Component {...match?.params} />}
                            </Route>
                        ))}
                    </Switch>

                    <Footer />
                </Fragment>
            )}
        </BrowserRouter>
    );
};
