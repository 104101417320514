import { FC, useContext } from "react";
import { Icon } from "../components/Icon";
import { GlobalContext } from "../contexts/GlobalContext";
import { createMarkup, createFlatMarkup } from "../utils/Helpers";
import { HubspotForm } from "./HubspotForm";

export const Footer: FC = () => {
    const { hideExternalLinks, survey } = useContext(GlobalContext);

    if (survey == null) {
        return null;
    }

    const footerText = hideExternalLinks
        ? createFlatMarkup(survey.Content.Global.Footer)
        : createMarkup(survey.Content.Global.Footer);

    const subfooterText = hideExternalLinks
        ? createFlatMarkup(survey.Content.Global.Subfooter)
        : createMarkup(survey.Content.Global.Subfooter);

    return (
        <footer className="footer">
            <div className="footer__top">
                <div className="footer__text" dangerouslySetInnerHTML={footerText}></div>
                {!hideExternalLinks && survey.Settings.GlobalFooterSocialLinks && (
                    <div className="footer__icons">
                        <ul>
                            <li>
                                <a
                                    title="LinkedIn"
                                    href="https://www.linkedin.com/company/liquid-interactive/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon icon="linkedin" />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Facebook"
                                    href="https://www.facebook.com/LiquidInteractive1/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon icon="facebook" />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Twitter"
                                    href="https://twitter.com/_liquidint"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon icon="twitter" />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Instagram"
                                    href="https://www.instagram.com/_liquidint"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon icon="instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>

            {survey?.Settings.GlobalFooterNewsletterSignup && (
                <div className="footer__newsletter">
                    <HubspotForm portalId={4510557} formId="2da9c770-aa13-4fa7-8233-46af970b28c5" />
                </div>
            )}

            {survey?.Content.Global.Subfooter != null && (
                <div className="footer__subfooter" dangerouslySetInnerHTML={subfooterText}></div>
            )}
        </footer>
    );
};
