// used to render HTML from a string
export const createMarkup = (html: string) => ({ __html: html });

// used to render HTML from a string
export const createFlatMarkup = (html: string) => {
    const temp = document.createElement("div");
    temp.innerHTML = html;

    return { __html: temp.textContent || temp.innerText };
};

// set the page title
export const setTitle = (title: string): string => {
    document.title = `${title} - Collaboration Tool`;
    return title;
};

