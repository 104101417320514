import { ThemeType } from "../utils/Types";

export interface SurveyModel {
    Theme: ThemeType;
    Settings: SurveySettings;
    Content: SurveyContent;
    Questions: Array<SurveyQuestion>;
    Dimensions: Array<SurveyDimension>;
    AggregateBreakpoints: Array<SurveyAggregateBreakpoint>;
}

export interface SurveySettings {
    GlobalHomeLink: boolean;
    GlobalFooterNewsletterSignup: boolean;
    GlobalFooterSocialLinks: boolean;
    ResultsCollapsed: boolean;
    ResultsShowContactSection: boolean;
    ResultsShowRetakeQuizButton: boolean;
    ResultsDimensionTitleFullWidth: boolean;
    ResultsDimensionBodyFullWidth: boolean;
}

export interface SurveyContent {
    Global: SurveyContentGlobal;
    Introduction: SurveyContentIntroduction;
    Survey: SurveyContentSurvey;
    Results: SurveyContentResults;
}

export interface SurveyContentGlobal {
    Footer: string;
    Subfooter: string;
}

export interface SurveyContentIntroduction {
    Title: string;
    Description1: string;
    Description2: string;
}

export interface SurveyContentSurvey {
    Prelude: string;
    FramingPrompt: string;
    CompletedScreen1: string;
    CompletedScreen2: string;
}

export interface SurveyContentResults {
    SummaryDescription: string;
    Heading: string;
}

export interface SurveyDimension {
    ID: number;
    Name: string;
    Body: string;
    IsSpecial: boolean;
    Breakpoints: Array<SurveyDimensionBreakpoint>;
}

export interface SurveyDimensionBreakpoint {
    ID: number;
    Name: string;
    Min?: number;
    Max?: number;
    Subtitle: string;
    Summary: string;
    ReadMore: string;
    DimensionID: number;
}

export interface SurveyAggregateBreakpoint {
    ID: number;
    Name: string;
    Primary: string;
    Min?: number;
    Max?: number;
}

export interface SurveyQuestion {
    ID: number;
    Name: string;
    Answers: Array<SurveyQuestionAnswer>;
}

export interface SurveyQuestionAnswer {
    ID: number;
    Name: string;
    Question: string;
    Dimensions: Array<SurveyQuestionAnswerDimension>;
}

export interface SurveyQuestionAnswerDimension {
    ID: number;
    Name: string;
    IsSpecial: boolean;
    Value: number;
}

export class SurveyModelHelpers {
    static getDimension(model: SurveyModel, id: number) {
        return model.Dimensions.find(x => x.ID === id);
    }
}
