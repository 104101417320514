import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { Icon } from "./Icon";

export const Navbar = () => {
    const { survey, hideExternalLinks } = useContext(GlobalContext);

    return (
        <header className="header">
            <div className="header__logo">
                {survey?.Settings.GlobalHomeLink && (
                    <a href="http://liquidinteractive.com.au/collab" target="_blank" rel="noopener noreferrer">
                        <span className="no-hover">
                            <span className="first">Liquid</span>
                            <span className="second">Collab</span>
                        </span>
                        <span className="hover">&lt; Home</span>
                    </a>
                )}
            </div>
            <div className="header__company">
                {hideExternalLinks ? (
                    <span>
                        <span className="d-none d-md-flex">
                            <Icon icon="liquid-logo-full" />
                        </span>
                        <span className="d-flex d-md-none">
                            <Icon icon="liquid-logo-half" />
                        </span>
                    </span>
                ) : (
                    <a href="https://liquidinteractive.com.au" target="_blank" rel="noopener noreferrer">
                        <span className="d-none d-md-flex">
                            <Icon icon="liquid-logo-full" />
                        </span>
                        <span className="d-flex d-md-none">
                            <Icon icon="liquid-logo-half" />
                        </span>
                    </a>
                )}
            </div>
        </header>
    );
};
