import { FC, Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { RouteList } from "../Routes";
import { Page } from "./Page";
import { SurveyQuestionAnswer } from "../models/SurveyModel";
import { SurveyResponseModel } from "../models/crud/SurveyResponseModel";
import { SurveyService } from "../services/SurveyService";
import { GlobalContext } from "../contexts/GlobalContext";
import { Constants } from "../utils/Constants";
import { createMarkup } from "../utils/Helpers";
import { SurveyCompletion } from "../components/SurveyCompletion";

export const SurveyPage: FC = () => {
    const history = useHistory();

    const { surveyId } = Constants;

    const { survey, getHomeUrl } = useContext(GlobalContext);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState<Array<SurveyQuestionAnswer>>([]);
    const [showFramingPrompt, setShowFramingPrompt] = useState(survey?.Content.Survey.FramingPrompt != null);

    const selectAnswer = (a: SurveyQuestionAnswer) => {
        setSelectedAnswers([...selectedAnswers, a]);

        setCurrentQuestion(currentQuestion + 1);

        // scroll back to the top for mobile
        window.scroll(0, 0);
    };

    const submitSurvey = () => {
        const selectedAnswerIds = selectedAnswers.map(a => a.ID.toString());

        // post survey data
        const model: SurveyResponseModel = {
            SurveyID: +surveyId,
            AnswerIDs: selectedAnswerIds.map(x => +x),
        };

        SurveyService.submitSurvey(model);

        // go to results page
        const url = RouteList.Results(selectedAnswerIds);
        history.push(url);
    };

    const goBack = () => {
        if (currentQuestion === 0) {
            history.push(getHomeUrl());
        } else {
            setCurrentQuestion(currentQuestion - 1);
            selectedAnswers.pop();

            // scroll back to the top for mobile
            window.scroll(0, 0);
        }
    };

    // don't render an empty survey
    if (survey == null) {
        return null;
    }

    return (
        <Page className="page-survey">
            {showFramingPrompt ? (
                <Container>
                    <div
                        className="type-m fw-normal framing-prompt"
                        dangerouslySetInnerHTML={createMarkup(survey.Content.Survey.FramingPrompt)}
                    ></div>

                    <button type="button" className="btn btn-primary" onClick={() => setShowFramingPrompt(false)}>
                        Okay
                    </button>
                </Container>
            ) : (
                <Fragment>
                    {survey.Questions.map((q, qIndex) => (
                        <Container className="container--full" key={q.ID} hidden={qIndex !== currentQuestion}>
                            {qIndex === 0 && survey.Content.Survey.Prelude && (
                                <Row>
                                    <Col sm={{ size: 8 }} md={{ size: 6, offset: 1 }}>
                                        <div className="mb-4 mb-md-5 type-caption">{survey.Content.Survey.Prelude}</div>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col md={1}>
                                    <div className="counter mb-3">
                                        {qIndex + 1}/{survey.Questions.length}
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="type-xs fw-bold text-purple mb-4 mb-md-5">{q.Name}</div>

                                    <div className="mb-4 mb-lg-5">
                                        {q.Answers.map(a => (
                                            <div key={a.ID}>
                                                <button
                                                    type="button"
                                                    className="answer type-copy"
                                                    onClick={() => selectAnswer(a)}
                                                >
                                                    <span>{a.Name}</span>
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <Button color="primary" type="button" onClick={goBack} outline>
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    ))}

                    {currentQuestion === survey.Questions.length && <SurveyCompletion submitSurvey={submitSurvey} />}
                </Fragment>
            )}
        </Page>
    );
};
