import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { useFormState } from "../hooks/useFormState";
import { SurveyModel } from "../models/SurveyModel";
import { SurveyService } from "../services/SurveyService";
import { Constants } from "../utils/Constants";
import * as querystring from "query-string";
import { RouteList } from "../Routes";

export const useGlobalContext = () => {
    const { surveyId } = Constants;

    const { loading, setLoading, confirmServerError } = useFormState(true);

    const [survey, setSurvey] = useState<SurveyModel>();
    const query = querystring.parseUrl(window.location.href);

    const hideExternalLinks = query.query.external === "hide";

    const loadData = useCallback(() => {
        SurveyService.getSurvey(+surveyId)
            .then(x => {
                setSurvey(x);
            })
            .catch(confirmServerError);
    }, [confirmServerError, surveyId, setLoading]);

    useEffect(loadData, []);

    const getHomeUrl = () => {
        if (hideExternalLinks) {
            return `${RouteList.Home}?external=hide`;
        } else {
            return RouteList.Home;
        }
    };

    return {
        survey,
        loading,
        setLoading,
        hideExternalLinks,
        getHomeUrl,
    };
};

// automatic exports
export type IGlobalContext = ReturnType<typeof useGlobalContext>;
export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

type Props = {
    children: ReactNode;
};

export const GlobalContextProvider = (props: Props) => {
    const globalContext = useGlobalContext();
    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};
