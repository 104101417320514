import { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Page } from "./Page";
import { createMarkup } from "../utils/Helpers";
import { NewsletterSignup } from "../components/NewsletterSignup";
import { Col, Container, Row } from "reactstrap";
import {
    SurveyAggregateBreakpoint,
    SurveyDimensionBreakpoint,
    SurveyModelHelpers,
    SurveyQuestionAnswer,
} from "../models/SurveyModel";
import { Link } from "react-router-dom";
import { Icon } from "../components/Icon";
import { Tracks } from "../components/Tracks";
import { GlobalContext } from "../contexts/GlobalContext";

type Props = {
    answers: Array<string>;
    id: string;
};

export const ResultsPage: FC<Props> = (props: Props) => {
    const { answers } = props;

    const { survey, getHomeUrl } = useContext(GlobalContext);

    const [selectedAnswers] = useState<Array<SurveyQuestionAnswer>>([]);
    const [selectedBreakpoints] = useState<Array<SurveyDimensionBreakpoint>>([]);
    const [selectedAggregateBreakpoint, setSelectedAggregateBreakpoint] = useState<SurveyAggregateBreakpoint>();

    const loadData = useCallback(() => {
        if (survey) {
            let rollingAggregateScore = 0;

            // get the selected answers from the query string
            survey.Questions.forEach(q => {
                q.Answers.forEach(a => {
                    if (answers && answers.indexOf(a.ID.toString()) > -1) {
                        selectedAnswers.push(a);
                    }
                });
            });

            // determine the dimension
            for (const dimension of survey.Dimensions) {
                let dimensionPoints = 0;

                for (const answer of selectedAnswers) {
                    const foundDimension = answer.Dimensions.find(x => x.ID === dimension.ID);

                    if (foundDimension) {
                        dimensionPoints += foundDimension.Value;

                        if (!foundDimension.IsSpecial) {
                            rollingAggregateScore += foundDimension.Value;
                        }
                    }
                }

                // get the breakpoint
                for (const breakpoint of dimension.Breakpoints) {
                    if (
                        (breakpoint.Min == null || dimensionPoints >= breakpoint.Min) &&
                        (breakpoint.Max == null || dimensionPoints <= breakpoint.Max)
                    ) {
                        selectedBreakpoints.push(breakpoint);
                    }
                }
            }

            // determine aggregate breakpoint
            for (const breakpoint of survey.AggregateBreakpoints) {
                if (
                    (breakpoint.Min == null || rollingAggregateScore >= breakpoint.Min) &&
                    (breakpoint.Max == null || rollingAggregateScore <= breakpoint.Max)
                ) {
                    setSelectedAggregateBreakpoint(breakpoint);
                }
            }
        }
    }, [survey]);

    useEffect(loadData, [loadData]);

    return (
        <Page className="page-results">
            {survey && (
                <div className="d-flex flex-column">
                    <Container>
                        <div className="d-flex">
                            {survey.Content.Results.Heading && (
                                <h2 className="mx-auto type-m d-inline-block underline underline--black">
                                    {survey.Content.Results.Heading}
                                </h2>
                            )}
                        </div>
                    </Container>

                    {selectedAggregateBreakpoint && (
                        <section className="section-summary">
                            <Container>
                                <div className="section-summary__holder">
                                    <div className="section-summary__inner">
                                        <h1 className="section-summary__heading">{selectedAggregateBreakpoint.Name}</h1>

                                        <div
                                            className="mb-4 section-summary__primary"
                                            dangerouslySetInnerHTML={createMarkup(selectedAggregateBreakpoint.Primary)}
                                        ></div>

                                        {survey.Content.Results.SummaryDescription && (
                                            <div className="section-summary__description">
                                                {survey.Content.Results.SummaryDescription}
                                            </div>
                                        )}

                                        {survey.Settings.ResultsShowRetakeQuizButton && (
                                            <div>
                                                <Link to={getHomeUrl()} className="btn btn-warning text-dark">
                                                    Take the quiz again
                                                    <Icon icon="arrow" />
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Container>
                        </section>
                    )}

                    <section className="section-breakdown">
                        <Container>
                            <ul className="list-unstyled mb-0">
                                {selectedBreakpoints.map((b, bIndex) => {
                                    const id = `collapse-${b.ID}`;
                                    const dimension = SurveyModelHelpers.getDimension(survey, b.DimensionID);

                                    return (
                                        dimension &&
                                        !dimension.IsSpecial && (
                                            <li key={b.ID} className="breakpoint">
                                                <Row>
                                                    {survey.Settings.ResultsDimensionTitleFullWidth && (
                                                        <Col lg={12}>
                                                            <div className="text-center mb-5 container-mini">
                                                                <h3 className="type-xxl">{dimension.Name}</h3>

                                                                {survey.Settings.ResultsDimensionBodyFullWidth && (
                                                                    <div
                                                                        className="type-copy mt-4"
                                                                        dangerouslySetInnerHTML={createMarkup(
                                                                            dimension.Body,
                                                                        )}
                                                                    ></div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    )}

                                                    <Col lg={4}>
                                                        <Tracks
                                                            bIndex={bIndex}
                                                            currentBreakpoint={b}
                                                            breakpoints={dimension.Breakpoints}
                                                        />
                                                    </Col>

                                                    <Col lg={4}>
                                                        {!survey.Settings.ResultsDimensionTitleFullWidth && (
                                                            <Fragment>
                                                                <h3
                                                                    className={clsx("type-s", "mb-4", [
                                                                        bIndex === 0 && "text-red",
                                                                        bIndex === 1 && "text-purple-light",
                                                                        bIndex === 2 && "text-blue",
                                                                        bIndex === 3 && "text-green",
                                                                        bIndex === 4 && "text-blue2",
                                                                    ])}
                                                                >
                                                                    {dimension.Name}
                                                                </h3>

                                                                <div
                                                                    className="type-xs mb-4 text-purple"
                                                                    dangerouslySetInnerHTML={createMarkup(b.Subtitle)}
                                                                ></div>
                                                            </Fragment>
                                                        )}

                                                        {survey.Settings.ResultsDimensionTitleFullWidth && (
                                                            <div
                                                                className="type-m breakpoint__subtitle"
                                                                dangerouslySetInnerHTML={createMarkup(b.Subtitle)}
                                                            ></div>
                                                        )}

                                                        <div
                                                            className="type-copy mb-4"
                                                            dangerouslySetInnerHTML={createMarkup(b.Summary)}
                                                        ></div>

                                                        {survey.Settings.ResultsCollapsed && (
                                                            <button
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#${id}`}
                                                                className="btn-collapse type-copy text-purple mb-0 collapsed"
                                                            >
                                                                <span className="d-flex">
                                                                    <span>Read more</span>
                                                                    <span>
                                                                        <Icon icon="dropdown" />
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        )}

                                                        <div
                                                            id={`${id}`}
                                                            className={clsx(
                                                                survey.Settings.ResultsCollapsed && "collapse",
                                                            )}
                                                        >
                                                            <div
                                                                className={clsx(
                                                                    survey.Settings.ResultsCollapsed && "pt-4",
                                                                )}
                                                            >
                                                                <div
                                                                    className="type-copy mb-4 breakpoint__read-more"
                                                                    dangerouslySetInnerHTML={createMarkup(b.ReadMore)}
                                                                ></div>

                                                                {!survey.Settings.ResultsDimensionBodyFullWidth && (
                                                                    <div
                                                                        className="type-copy mt-4"
                                                                        dangerouslySetInnerHTML={createMarkup(
                                                                            dimension.Body,
                                                                        )}
                                                                    ></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    );
                                })}
                            </ul>
                        </Container>
                    </section>

                    {survey.Settings.ResultsShowContactSection && <NewsletterSignup />}
                </div>
            )}
        </Page>
    );
};
