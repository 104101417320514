import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { RouteList } from "../../Routes";

export const RedirectSurveyId = () => {
    const history = useHistory();

    history.push(RouteList.Survey);

    return <Fragment>Redirecting</Fragment>;
};
