import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { GlobalContextProvider } from "./contexts/GlobalContext";

ReactDOM.render(
    <GlobalContextProvider>
        <App />
    </GlobalContextProvider>,
    document.getElementById("root"),
);

serviceWorker.unregister();

// serviceWorker.register({
//     onUpdate: (registration: ServiceWorkerRegistration) => {
//         if (window.confirm("New version available.  Reload?")) {
//             registration.unregister().then(() => {
//                 window.location.reload();
//             });
//         }
//     },
// });
